<template>
  <section class="container">
    <Notificacao :modal="modal" />

    <div class="news-letter">
      <h3>
        Conheça o melhor AVA do Brasil, o diferencial que o seu negócio precisa
        em apenas um clique.
      </h3>
      <form>
        <div class="inputs">
          <div class="nome">
            <label for="nome">Nome</label>
            <input
              id="nome-news"
              type="text"
              placeholder="Seu nome aqui"
              v-model="data.newsletter.name"
              required
              :disabled="loading"
            />
          </div>
          <div class="email">
            <label for="email">E-mail</label>
            <input
              type="email"
              placeholder="Seu email aqui"
              v-model="data.newsletter.email"
              required
              :disabled="loading"
            />
          </div>
        </div>

        <div>
          <button
            class="btn btn-submit"
            @click.prevent="onSubmit"
            :disabled="loading"
          >
            {{ loading ? "ENVIADO DADOS..." : "ACESSAR MATERIAL" }}
          </button>
        </div>
      </form>
      <div class="politica">
        <a href="https://atheva-minerva.s3.sa-east-1.amazonaws.com/privacy_policy.pdf" target="_blank">Conheça nossa política de privacidade</a>
      </div>
    </div>
    <img
      src="@/assets/icons/detalhe_01.svg"
      class="detalhe"
      data-aos="fade-down"
    />
  </section>
</template>

<script>
import { api } from "@/services.js";
import Notificacao from "@/components/Notificacao";

export default {
  name: "NewsLetter",
  data() {
    return {
      loading: false,
      modal: {
        show: false,
        type: "",
        title: "",
        mensagem: "",
      },
      data: {
        platform: "atheva_web",
        newsletter: {
          name: "",
          email: "",
        },
      },
    };
  },
  components: {
    Notificacao,
  },
  methods: {
    onSubmit() {
      if (this.data.newsletter.name && this.data.newsletter.email) {
        this.loading = true;
        api
          .post("/newsletter", this.data)
          .then((response) => {
            if (response.status == 201) {
              this.modal = {
                type: "success",
                title: "Dados enviados com sucesso!",
                mensagem: "Seus dados foram enviados com sucesso.",
                show: true,
              };

              this.data = {
                platform: "atheva_web",
                newsletter: {
                  name: "",
                  email: "",
                },
              };
            } else {
              this.modal = {
                type: "error",
                title: "Um erro ocorreu!",
                mensagem: "Verifique os dados e tente novamente",
                show: true,
              };
            }
          })
          .catch((err) => {
            console.log(err);
            this.modal = {
              type: "error",
              title: "Um erro ocorreu!",
              mensagem: "Verifique os dados e tente novamente",
              show: true,
            };
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        alert("Preencha os campos necessários");
        document.querySelector("#nome-news").focus();
      }
    },
  },
};
</script>

<style scoped>
section {
  position: relative;
}
.news-letter {
  position: relative;
  top: -100px;
  margin: 0 auto;
  background: #fff;
  padding: 32px;
  border-radius: 5px;
  box-shadow: 0px 8px 16px #0000001a;
}
h3 {
  color: #393939;
  font-size: 20px;
  text-align: center;
  font-weight: 500;
}
form,
.inputs {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 32px;
}
form {
  align-items: flex-end;
}
.nome input {
  width: 280px;
  height: 50px;
  margin: 0;
}
.email input {
  width: 470px;
  height: 50px;
  margin: 0;
}
.detalhe {
  position: absolute;
  left: 120px;
  bottom: 0;
  z-index: -1;
}
.politica {
  text-align: center;
  text-decoration: underline;
  margin-top: 1rem;
  color: #ccccb4;
}

.politica a{
  color: #ccccb4;
}

@media screen and (max-width: 768px) {
  section {
    overflow: initial;
  }
  h3 {
    font-size: 18px;
  }
  .news-letter {
    top: -200px;
    padding: 32px 24px;
  }
  form,
  .inputs {
    display: block;
  }
  .email {
    margin-top: 32px;
  }
  .nome input,
  .email input {
    width: 100%;
  }
  .btn-submit {
    margin-top: 32px;
    width: 100%;
    font-size: 14px;
  }
  .detalhe {
    left: -100px;
    top: 150px;
  }
}
</style>