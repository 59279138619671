<template>
  <div class="count-up" @click="init">
    <div>
      <h4>+ <span :id="id">0</span> {{ title }}</h4>
      <p :class="{ quebra: text == 'desafios gamificados' }">{{ text }}</p>
    </div>
  </div>
</template>

<script>
import { CountUp } from "countup.js";
export default {
  props: {
    id: {
      type: String,
    },
    number: {
      type: Number,
    },
    title: {
      type: String,
    },
    text: {
      type: String,
    },
  },
  data() {
    return {};
  },
  methods: {
    init() {
      const op = {
        duration: 4,
      };

      const id = document.querySelector(`#${this.id}`);
      if (id && id.innerText == "0") {
        id.innerText = "";
        const countUp = new CountUp(this.id, this.number, op);
        countUp.start();
      }
    },
    debounce(func, wait) {
      let timer = null;
      return function () {
        clearTimeout(timer);
        timer = setTimeout(func, wait);
      };
    },
  },

  mounted() {
    const item = document.querySelector(".count-up");
    const windowMetade = window.innerHeight * 0.6;
    let offset = item.offsetTop - windowMetade;

    window.addEventListener(
      "scroll",
      this.debounce(() => {
        if (window.pageYOffset > offset) {
          this.init();
        }
      }, 500)
    );
  },
};
</script>

<style scoped>
.count-up {
  padding: 15px 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 198px;
  height: 198px;
  background: url("../assets/icons/detalhe-number.svg") center center no-repeat;
}
.count-up h4 {
  font-size: 24px;
  font-weight: 800;
  margin: 0;
  margin-bottom: 12px;
}
.count-up p {
  font-size: 15px;
  text-align: center;
  margin: 0 auto;
}
.count-up p.quebra {
  width: 60%;
}
@media screen and (max-width: 768px) {
  .count-up {
    width: 350px;
  }
  .count-up p {
    width: 80%;
    margin: 0 auto;
  }
}
</style>