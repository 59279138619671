<template>
  <div class="card" @click="goRouter">
    <img :src="imageIcon" />
    <h3>{{ title }}</h3>
    <p>{{ text }}</p>
    <router-link :to="rota">Saiba mais ></router-link>
  </div>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
    },
    title: {
      type: String,
    },
    text: {
      type: String,
    },
    rota: {
      type: String,
    },
  },
  data() {
    return {};
  },
  computed: {
    imageIcon() {
      return require(`@/assets/icons/${this.icon}.svg`);
    },
  },
  methods: {
    goRouter() {
      this.$router.push(this.rota);
    },
  },
};
</script>

<style scoped>
.card {
  padding: 48px 24px;
  background: #fff;
  border: 1px solid #dedede;
  border-radius: 8px;
  width: 265px;
  height: 354px;
  transition: 0.3s;
  position: relative;
  cursor: pointer;
}
.card:hover {
  box-shadow: 0px 8px 16px #0000001a;
  border-color: transparent;
}
.card h3::before {
  content: "";
  width: 40px;
  height: 4px;
  border-radius: 4px;
  background: #f9b010;
  display: block;
  margin: 16px 0;
}
.card h3 {
  font-size: 18px;
  font-weight: 800;
  margin-bottom: 8px;
}
.card p {
  font-size: 15px;
  color: #747474;
  line-height: 1.5;
}
.card a {
  position: absolute;
  bottom: 25px;
  color: #747474;
  font-size: 14px;
  transition: 0.3s;
}
.card a:hover {
  color: #000;
  font-weight: 600;
}
@media screen and (max-width: 768px) {
  .card {
    width: 100%;
    height: 354px;
  }
  .card a {
    bottom: 40px;
  }
}
</style>