<template>
  <section>
    <div class="banner">
      <div class="info-banner container">
        <h1 data-aos="fade-down">
          O Ambiente Virtual de Aprendizagem que<br class="desktop" />
          está transformando a educação do Brasil
        </h1>
        <p data-anima="bottom">
          Mais
          <vue-typer
            :text="[
              'destaque para os materiais didáticos da Editora Barsa.',
              'resultados para os alunos da plataforma Inteligente.',
              'aprendizado nas prefeituras da rede Escalier.',
              'tecnologia nas escolas da rede IteckEdu.',
              'engajamento para os estudantes do Kademi.',
              'protagonismo para os professores do Geniuz.',
            ]"
            :repeat="Infinity"
            :shuffle="false"
            initial-action="typing"
            :pre-type-delay="70"
            :type-delay="70"
            :pre-erase-delay="2000"
            :erase-delay="250"
            erase-style="clear"
            :erase-on-complete="false"
            caret-animation="blink"
          ></vue-typer>
        </p>
        <div data-aos="fade-up">
          <a href="#/contato" class="btn btn-contato">CONTATO</a>
        </div>
      </div>
    </div>
    <NewsLetter />

    <div class="video" @click="video = true">
      <img src="@/assets/icons/icon-play.svg" class="icon-play" />
      <img src="@/assets/icons/wave-02.svg" class="wave" data-aos="fade-left" />
    </div>
    <div class="video-frame" @click="closeVideo" id="video-frame" v-if="video">
      <iframe
        data-anima="bottom"
        src="https://www.youtube.com/embed/TF4c2XS82W8?controls=0&autoplay=1"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </div>

    <div class="container tecnologia">
      <div class="info-text" data-aos="fade-right">
        <h2>Tecnologia que conecta seu <b>negócio</b> ao <b>presente.</b></h2>
        <p>
          O avanço digital é inevitável e cada vez mais necessário para que
          alunos e educadores conquistem, retenham e se apaixonem pelo
          conhecimento, uma vez que a tecnologia já é uma realidade social e
          está presente em praticamente todos os ambientes de convívio. Da casa
          ao trabalho, as pessoas estão conectadas! Portanto, é chegada a hora
          da maior e mais inovadora transformação na educação brasileira, e nós
          estamos aqui justamente para impulsionar esse feito importante no
          país. Conheça o nosso trabalho.
        </p>
      </div>
      <div class="ilustracao">
        <img
          src="@/assets/icons/detalhe-tec.svg"
          class="detalhe"
          data-aos="fade-up-left"
        />
        <img
          data-aos="fade-left"
          src="@/assets/img/img-tec.png"
          alt="Imagem Tecnologia"
          class="img-tec"
        />
      </div>
    </div>

    <div class="container atuamos" id="onde-atuamos">
      <img
        src="@/assets/icons/detalhe-atuamos.svg"
        class="detalhe-atuamos"
        data-aos="fade-down-right"
      />
      <h2>Onde <b>atuamos</b></h2>
      <p>
        Atualmente as nossas soluções levam oportunidades e possibilitam a
        apropriação da tecnologia para os mais diferentes segmentos:
      </p>

      <div class="cards" data-aos="fade-up">
        <div
          class="item-atuacao"
          v-for="card in cardsAtuacao"
          :key="card.title"
        >
          <CardAtuacao
            :icon="card.icon"
            :title="card.title"
            :text="card.text"
            :rota="card.rota"
          />
        </div>
        <img
          src="@/assets/icons/wave-02.svg"
          class="wave-atuamos"
          data-aos="fade-right"
        />
      </div>
    </div>

    <div class="container numbers" data-aos="fade-right">
      <div class="number-item" v-for="n in numbers" :key="n.id">
        <CountUp
          :number="n.number"
          :title="n.title"
          :text="n.text"
          :id="n.id"
        />
      </div>
    </div>

    <div class="ava">
      <div class="container container-motivos" id="nosso-AVA">
        <h2 data-aos="fade-down">
          O <b>AVA</b> que está <b>mudando o Brasil</b>
        </h2>
        <p data-aos="fade-up">10 motivos que tornam o nosso projeto único.</p>

        <div class="motivos">
          <ul>
            <li>
              <div class="checkbox">
                <input
                  type="radio"
                  name="modularidade"
                  id="modularidade"
                  class="checkMotivo"
                  @change="changeCheck"
                  checked
                />
                <label for="modularidade">Modularidade</label>
              </div>
              <p v-if="motivo == 'modularidade'">
                Oferte seu AVA em diferentes formatos e planos.
              </p>
            </li>
            
            <li>
              <div class="checkbox">
                <input
                  type="radio"
                  name="BNCC"
                  id="BNCC"
                  class="checkMotivo"
                  @change="changeCheck"
                />
                <label for="BNCC">BNCC</label>
              </div>
              <p v-if="motivo == 'BNCC'">
                A solução digital conectada às diretrizes nacionais.
              </p>
            </li>

            <li>
              <div class="checkbox">
                <input
                  type="radio"
                  name="gamificacao"
                  id="gamificacao"
                  class="checkMotivo"
                  @change="changeCheck"
                />
                <label for="gamificacao">Gamificação e engajamento</label>
              </div>
              <p v-if="motivo == 'gamificacao'">
                Jogos, interações e incentivos, tudo num lugar só.
              </p>
            </li>

            <li>
              <div class="checkbox">
                <input
                  type="radio"
                  name="multitela"
                  id="multitela"
                  class="checkMotivo"
                  @change="changeCheck"
                />
                <label for="multitela">Multitela responsiva</label>
              </div>
              <p v-if="motivo == 'multitela'">
                Desktop, computador, tablet ou smartphone: Você decide onde quer
                usar!
              </p>
            </li>

            <li>
              <div class="checkbox">
                <input
                  type="radio"
                  name="gestao"
                  id="gestao"
                  class="checkMotivo"
                  @change="changeCheck"
                />
                <label for="gestao">Gestão inteligente</label>
              </div>
              <p v-if="motivo == 'gestao'">
                Relatórios em multiníveis para gerenciar sua rede.
              </p>
            </li>

            <li>
              <div class="checkbox">
                <input
                  type="radio"
                  name="biblioteca"
                  id="biblioteca"
                  class="checkMotivo"
                  @change="changeCheck"
                />
                <label for="biblioteca">Biblioteca digital</label>
              </div>
              <p v-if="motivo == 'biblioteca'">
                Um acervo com milhares de materiais, inclusive os seus.
              </p>
            </li>

            <li>
              <div class="checkbox">
                <input
                  type="radio"
                  name="white"
                  id="white"
                  class="checkMotivo"
                  @change="changeCheck"
                />
                <label for="white">White Label</label>
              </div>
              <p v-if="motivo == 'white'">Personalize seu AVA com sua marca.</p>
            </li>

            <li>
              <div class="checkbox">
                <input
                  type="radio"
                  name="atividades"
                  id="atividades"
                  class="checkMotivo"
                  @change="changeCheck"
                />
                <label for="atividades"
                  >Atividades síncronas e assíncronas</label
                >
              </div>
              <p v-if="motivo == 'atividades'">
                Funções ao vivo ou agendado, na escola ou em casa. Um universo
                de possibilidades.
              </p>
            </li>

            <li>
              <div class="checkbox">
                <input
                  type="radio"
                  name="acessibilidade"
                  id="acessibilidade"
                  class="checkMotivo"
                  @change="changeCheck"
                />
                <label for="acessibilidade"
                  >Acessibilidade e inclusão</label
                >
              </div>
              <p v-if="motivo == 'acessibilidade'">
                Ferramentas de acessibilidade que promovem a inclusão no ensino.
              </p>
            </li>

            <li>
              <div class="checkbox">
                <input
                  type="radio"
                  name="ecossistema"
                  id="ecossistema"
                  class="checkMotivo"
                  @change="changeCheck"
                />
                <label for="ecossistema"
                  >Ecossistema de aprendizagem</label
                >
              </div>
              <p v-if="motivo == 'ecossistema'">
                Ambientes lúdicos que estimulam o protagonismo do aluno.
              </p>
            </li>
          </ul>

          <div class="img-motivo">
            <img
              src="@/assets/img/modularidade.png"
              alt="modularidade"
              v-if="motivo == 'modularidade'"
              data-anima="right"
              id="img-modularidade"
            />
            <img
              src="@/assets/img/BNCC.png"
              alt="BNCC"
              v-if="motivo == 'BNCC'"
              data-anima="right"
            />
            <img
              src="@/assets/img/gamificacao.png"
              alt="gamificacao"
              v-if="motivo == 'gamificacao'"
              data-anima="right"
            />
            <img
              src="@/assets/img/multitela.png"
              alt="multitela"
              v-if="motivo == 'multitela'"
              data-anima="right"
            />
            <img
              src="@/assets/img/gestao.png"
              alt="gestao"
              v-if="motivo == 'gestao'"
              data-anima="right"
            />
            <img
              src="@/assets/img/biblioteca.png"
              alt="biblioteca"
              v-if="motivo == 'biblioteca'"
              data-anima="right"
            />
            <img
              src="@/assets/img/white.png"
              alt="white"
              v-if="motivo == 'white'"
              data-anima="right"
            />
            <img
              src="@/assets/img/atividades.png"
              alt="atividades"
              v-if="motivo == 'atividades'"
              data-anima="right"
            />
            <img
              src="@/assets/img/atividades.png"
              alt="atividades"
              v-if="motivo == 'acessibilidade'"
              data-anima="right"
            />
            <img
              src="@/assets/img/atividades.png"
              alt="atividades"
              v-if="motivo == 'ecossistema'"
              data-anima="right"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="servicos">
      <div class="container">
        <h2>Conheça mais diferenciais do <b>nosso serviço</b></h2>

        <ul>
          <li
            v-for="servico in servicos"
            :key="servico.text"
            class="item-servico"
          >
            <img src="@/assets/icons/check.svg" />
            {{ servico.text }}
          </li>
        </ul>
      </div>
    </div>

    <div class="container quem-nos-conhece" id="quem-nos-conhece">
      <div>
        <h2 data-aos="fade-left">Quem nos <b>conhece</b></h2>
        <p data-aos="fade-left">Conheça quem confia na Atheva</p>
        <img
          src="@/assets/icons/wave-02.svg"
          data-aos="fade-right"
          class="wave-quem-conhece"
        />
      </div>
      <div class="parceiros-container">
        <div
          data-aos="fade-right"
          class="parceiro-item"
          v-for="parceiro in parceiros"
          :key="parceiro.nome"
        >
          <Parceiro :parceiro="parceiro" />
        </div>
        <img
          src="@/assets/icons/detalhe_01.svg"
          class="detalhe-parceiro"
          data-aos="fade-left"
        />
      </div>
    </div>

    <div class="container blog" id="blog">
      <carrosel-post>
        <div>
          <h2>
            Acompanhe o que há de novo em <b> Tecnologia, Educação</b> e
            <b>Engajamento</b>
          </h2>
        </div>
      </carrosel-post>
    </div>
  </section>
</template>

<script>
import NewsLetter from "@/components/NewsLetter";
import CardAtuacao from "@/components/CardAtuacao";
import CountUp from "@/components/CountUp";
import Parceiro from "@/components/Parceiro";
import CarroselPost from "@/components/CarroselPost.vue";
import { VueTyper } from "vue-typer";

export default {
  name: "Home",
  data() {
    return {
      avaItens: [],
      indexAva: 0,
      video: false,
      cardsAtuacao: [
        {
          icon: "icon-distribuidores",
          title: "Distribuidores",
          text: "A oferta digital mais criativa para o seu portfólio.",
          rota: "/distribuidores",
        },
        {
          icon: "icon-editoras",
          title: "Editoras",
          text: "O AVA com a cara da sua editora. Conectada, interativa e 100% digital.",
          rota: "/editoras",
        },
        {
          icon: "icon-sistema",
          title: "Sistemas de ensino",
          text: "A solução mais prática para fidelizar alunos, professores e gestores ao seu sistema de ensino.",
          rota: "/sistema-de-ensino",
        },
        {
          icon: "icon-prefeitura",
          title: "Prefeituras e Governos",
          text: "Sem essa de versão light. A mesma tecnologia para rede pública e privada.",
          rota: "/prefeituras-e-governos",
        },
        {
          icon: "icon-empresas",
          title: "Empresas e Promoções",
          text: "Marketing inteligente que fideliza e aumenta as suas vendas.",
          rota: "/empresas-e-promocoes",
        },
        {
          icon: "icon-cooporativo",
          title: "Corporativo/RH",
          text: "Estimule o desenvolvimento e potencial de seus colaboradores.",
          rota: "/coportativo-rh",
        },
        {
          icon: "icon-cursos",
          title: "Cursos e Ensino Técnico",
          text: "O diferencial que a sua instituição precisa para dar aquele upgrade no ensino.",
          rota: "/cursos-e-ensino-tecnico",
        },
        {
          icon: "icon-universidade",
          title: "Universidades",
          text: "Ofereça um ambiente de qualidade e super engajador para as aulas híbridas.",
          rota: "/universidades",
        },
      ],
      numbers: [
        {
          id: "interacoes",
          number: 3.75,
          title: "Milhões",
          text: "de interações em nosso AVA",
        },
        {
          id: "usuarios",
          number: 180,
          title: "Mil",
          text: "usuários já utilizam o nosso sistema",
        },
        {
          id: "educadores",
          number: 16,
          title: "Mil",
          text: "educadores em nossa rede",
        },
        {
          id: "desafios",
          number: 7,
          title: "Mil",
          text: "desafios gamificados",
        },
        {
          id: "instituicoes",
          number: 950,
          title: "",
          text: "instituições ligadas ao futuro",
        },
      ],
      motivo: "modularidade",
      servicos: [
        {
          text: "Tecnologia em HTML 5.",
        },
        {
          text: "Conteúdos e propostas alinhadas à BNCC.",
        },
        {
          text: "Ferramenta de fórum integrado.",
        },

        {
          text: "Experiência mobile first.",
        },
        {
          text: "Plataforma 100% digital e online.",
        },
        {
          text: "Design segmentado por ciclo e idade.",
        },
        {
          text: "Ferramenta de autoria de jogos.",
        },

        {
          text: "Biblioteca com milhares de materiais.",
        },
        {
          text: "Dados inteligentes para gestão do município.",
        },

        {
          text: "Solução de avaliação continuada.",
        },
        {
          text: "Ferramentas de estudo livre e direcionado.",
        },
        {
          text: "Ferramenta de vídeo aula ao vivo.",
        },

        {
          text: "Recursos de engajamento e incentivos.",
        },
        {
          text: "Ferramenta de criação e correção de redação.",
        },

        {
          text: "Construtor de provas e simulados digitais.",
        },

        {
          text: "Múltiplos relatórios em diferentes níveis."
        },

        {
          text: "Ferramentas de acessibilidade e inclusão"
        },

        {
          text: "Trilha de aprendizagem"
        },
      ],
      parceiros: [
        {
          nome: "kademi.png",
          alt: "Logo Kademi",
          link: "https://www.kademi.com.br/",
        },
        {
          nome: "gestao.jpg",
          alt: "Logo Gestão",
          link: "https://gestaoeducacao.com.br/",
        },
        {
          nome: "escaledu.png",
          alt: "Logo Escaledu",
          link: "https://escalier.com.br/",
        },
        {
          nome: "geniuz.png",
          alt: "Logo Geniuz",
          link: "http://geniuzedu.com.br/",
        },
        {
          nome: "inagesp.png",
          alt: "Logo Inagesp",
          link: "https://inagesp.org.br/",
        },
        {
          nome: "grupo-planeta.jpg",
          alt: "Logo Grupo Planeta",
          link: "https://barsanarede.com.br/",
        },
        {
          nome: "iteck-edu.png",
          alt: "Logo IteckEdu",
          link: "http://iteckedu.com.br/",
        },
        {
          nome: "educplay.png",
          alt: "Logo EducPlay",
          link: "https://www.educplay.com.br/b2b",
        },
      ],
      client: {
        width: 0,
      },
    };
  },
  components: {
    NewsLetter,
    CardAtuacao,
    CountUp,
    Parceiro,
    CarroselPost,
    VueTyper,
  },
  computed: {
    isMobile() {
      return this.client.width <= 768;
    },
  },
  methods: {
    handleResize() {
      // padrão
      this.client.width = window.innerWidth;
    },
    changeCheck(e) {
      this.avaItens.forEach((item, index) => {
        if (item.id != e.target.id) {
          item.checked = false;
        } else {
          this.indexAva = index;
        }
      });

      if (e.target.checked) {
        this.motivo = e.target.id;
      } else {
        e.target.checked = true;
      }
    },
    closeVideo(e) {
      if (e.target.id == "video-frame") {
        this.video = false;
      }
    },
    initAnimaAva() {
      if (!this.isMobile) {
        this.avaItens = document.querySelectorAll(".checkMotivo");
        const time = setInterval(() => {
          if (this.avaItens[this.indexAva]) {
            this.avaItens[this.indexAva].click();

            this.indexAva++;
          } else {
            clearInterval(time);
          }

          if (this.indexAva == this.avaItens.length) {
            this.indexAva = 0;
            clearInterval(time);
            this.initAnimaAva();
          }

          if (this.isMobile) {
            clearInterval(time);
          }
        }, 5000);
      }
    },
  },
  mounted() {
    this.initAnimaAva();
  },
  created() {
    document.title = "Atheva";
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>

<style scoped>
.banner {
  width: 100%;
  height: 100vh;
  max-height: 688px;
  background: #747474 url("../assets/img/img-banner-01.png") no-repeat center
    center;
  background-size: cover;
}
.info-banner {
  padding-top: 150px;
}
.info-banner h1 {
  color: #fff;
  font-size: 36px;
  font-weight: 800;
}
.info-banner p {
  color: #fff;
  font-size: 24px;
  margin-top: 24px;
  margin-bottom: 60px;
}

@media screen and (max-width: 960px) {
  .info-banner h1 {
    font-size: 32px;
  }
  .info-banner p {
    font-size: 20px;
    margin-top: 20px;
    margin-bottom: 40px;
  }
}

.info-banner .btn-contato {
  display: inline-block;
  width: 220px;
  background: #fff;
}

@media screen and (max-width: 768px) {
  .desktop {
    display: none;
  }
  .banner {
    width: 100%;
    background: url("../assets/img/banner-mobile.png") no-repeat center center;
    background-size: cover;
  }
  .info-banner {
    padding-top: 80px;
  }
  .info-banner h1,
  .info-banner p {
    text-align: center;
    line-height: 1.6;
  }
  .info-banner h1 {
    margin-bottom: 25px;
    font-size: 22px;
  }

  .info-banner p {
    margin-bottom: 40px;
    font-size: 18px;
  }
  .info-banner .btn-contato {
    display: block;
    margin: 0 auto;
    font-size: 14px;
  }
}
/* video */
.video {
  background: url("../assets/img/img-video.png") no-repeat center center;
  width: 800px;
  height: 454px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  cursor: pointer;
  position: relative;
  box-shadow: 0px 8px 16px #0000001a;
}
.video img {
  transition: 0.3s;
}
.video:hover img {
  transform: scale(1.1);
}
.wave {
  position: absolute;
  z-index: -1;
  right: -130px;
  top: 80px;
}
.video-frame {
  display: flex;
  align-items: center;
  background: rgba(0, 0, 0, 0.7);
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999999;
}
.video-frame iframe {
  width: 960px;
  height: 600px;
  margin: 0 auto;
  box-shadow: 0px 8px 16px #0000001a;
}
@media screen and (max-width: 768px) {
  .video {
    width: 90%;
    background-size: cover;
    border-radius: 5px;
  }
  .video .icon-play {
    width: 80px;
    height: 80px;
  }
  .wave {
    right: -100px;
    top: -30px;
  }
  .video-frame iframe {
    width: 90%;
    height: 60vh;
  }
}

.tecnologia {
  margin-top: 100px;
  display: grid;
  /* grid-template-columns: 1fr 1fr; */
  grid-template-columns: 1fr 500px;
  align-items: center;
  gap: 30px;
  position: relative;
}
.tecnologia .info-text h2 {
  font-size: 28px;
  font-weight: 500;
  color: #000000;
  margin-bottom: 16px;
}
.tecnologia .info-text p {
  color: #747474;
  font-size: 18px;
  line-height: 1.5;
}
.tecnologia .ilustracao .img-tec {
  max-width: 700px;
}
.tecnologia .detalhe {
  position: absolute;
  max-width: 800px;
  right: -500px;
  top: -200px;
  z-index: -1;
  object-fit: contain;
}
@media screen and (max-width: 768px) {
  .tecnologia {
    margin-top: 48px;
    grid-template-columns: 1fr;
  }
  .tecnologia .info-text h2 {
    font-size: 20px;
  }
  .tecnologia .info-text p {
    font-size: 15px;
  }
  .tecnologia .ilustracao .img-tec {
    width: 100%;
  }
  .tecnologia .detalhe {
    display: none;
  }
}
.atuamos {
  margin-top: 70px;
  position: relative;
}
.atuamos h2 {
  font-weight: normal;
  font-size: 32px;
  margin-bottom: 16px;
  text-align: center;
}
.atuamos p {
  font-size: 18px;
  color: #747474;
  text-align: center;
}

.cards {
  margin-top: 48px;
  display: flex;
  align-items: center;
  gap: 16px;
  /* justify-content: space-between; */
  flex-wrap: wrap;
}
.detalhe-atuamos {
  position: absolute;
  left: -800px;
  top: -250px;
}
.wave-atuamos {
  position: absolute;
  right: -50px;
  bottom: 30px;
  z-index: -1;
}
@media screen and (max-width: 768px) {
  .atuamos {
    margin-top: 48px;
  }
  .atuamos h2 {
    font-size: 20px;
    text-align: left;
  }
  .atuamos p {
    font-size: 15px;
    text-align: left;
  }
  .cards {
    margin-top: 32px;
  }
}
.numbers {
  margin-top: 150px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media screen and (max-width: 768px) {
  .numbers {
    margin-top: 48px;
    overflow: auto;
    padding-bottom: 40px;
  }
}
.ava {
  margin-top: 122px;
  width: 100%;
  height: 100vh;
  height: 717px;
  background: url("../assets/img/img-ava.png") no-repeat center center;
  background-size: cover;
}
.container-motivos {
  padding: 96px 10px;
}
.container-motivos {
  color: #fff;
}
.container-motivos h2 {
  font-size: 32px;
  margin-bottom: 16px;
  font-weight: 500;
}
.container-motivos p {
  font-size: 18px;
}
.motivos {
  margin-top: 32px;
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.motivos ul li .checkbox {
  display: flex;
  align-items: center;
  gap: 20px;
}
.motivos ul li .checkbox input {
  width: 20px;
  height: 20px;
}
.motivos ul li .checkbox label {
  font-size: 18px;
  font-weight: 800;
  cursor: pointer;
}
.motivos ul li p {
  margin-left: 40px;
  margin-top: 5px;
  font-size: 15px;
}
.motivos ul li + li {
  margin-top: 15px;
}
#img-modularidade {
  margin-top: -80px;
}

@media screen and (max-width: 768px) {
  .ava {
    margin-top: 60px;
    height: auto;
  }
  .container-motivos {
    padding: 48px 10px;
  }
  .container-motivos h2 {
    font-size: 18px;
  }
  .container-motivos p {
    font-size: 15px;
  }
  .motivos {
    grid-template-columns: 1fr;
  }
  .motivos ul li .checkbox label {
    font-size: 16px;
  }
  .img-motivo {
    margin-top: 48px;
  }
  #img-modularidade {
    margin-top: auto;
  }
}

.servicos {
  background: #043e701a;
  padding: 96px 10px;
}
.servicos h2 {
  text-align: center;
  font-weight: 500;
}
.servicos ul {
  margin-top: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  flex-wrap: wrap;
}
.item-servico {
  display: flex;
  align-items: center;
  gap: 12px;
  width: 350px;
  margin-top: 30px;
  gap: 15px;
}
@media screen and (max-width: 768px) {
  .servicos {
    padding: 48px 10px;
  }
  .servicos h2 {
    font-size: 18px;
  }
  .servicos ul {
    margin-top: 32px;
  }
  .item-servico {
    width: 100%;
  }
}
.quem-nos-conhece {
  padding: 96px 10px;
  display: grid;
  grid-template-columns: 1fr 2fr;
}
.quem-nos-conhece h2 {
  font-size: 32px;
  font-weight: 500;
  margin-bottom: 16px;
}
.quem-nos-conhece p {
  font-size: 18px;
  color: #747474;
  margin-bottom: 100px;
}
.parceiros-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 25px;
  position: relative;
}
.detalhe-parceiro {
  position: absolute;
  right: -80px;
  bottom: 20px;
  z-index: -1;
}
@media screen and (max-width: 768px) {
  .quem-nos-conhece {
    padding: 64px 10px;
    grid-template-columns: 1fr;
  }
  .quem-nos-conhece h2 {
    font-size: 18px;
  }
  .quem-nos-conhece p {
    font-size: 15px;
    margin-bottom: 32px;
  }
  .wave-quem-conhece {
    display: none;
  }
  .parceiros-container {
    margin-top: 52px;
  }
}
.blog {
  margin-top: 96px;
}
@media screen and (max-width: 768px) {
  .blog {
    margin-top: 64px;
  }
}
</style>


