<template>
  <section>
    <slot data-aos="fade-left"></slot>
    <splide :options="options" @splide:mounted="onMounted" data-aos="fade-right" class="cards">
      <splide-slide
        class="card-item"
        v-for="item in cards"
        :key="item.id"
      >
        <img :src="item.src" :alt="item.filename" />

        <div class="info">
          <h3>{{ item.title }}</h3>
          <span>{{ item.posted_at }}</span>
          <p>{{ item.synopsis }}</p>
          <router-link :to="`/blog-post/${item.id}`">
            Continuar lendo
          </router-link>
        </div>
      </splide-slide>

      <template v-slot:controls>
        <div class="splide__progress">
          <div class="splide__progress__bar"></div>
        </div>
      </template>
    </splide>
    <div class="footer">
      <img src="@/assets/img/pattern.png" />
    </div>
  </section>
</template>

<script>
import "@splidejs/splide/dist/css/themes/splide-default.min.css";
import { Splide, SplideSlide } from "@splidejs/vue-splide";
import { api } from "@/services.js";

export default {
  name: "carrosel-post",

  components: {
    Splide,
    SplideSlide,
  },

  data() {
    return {
      cards: [],
      options: {
        type: "loop",
        padding: {
          right: "5rem",
          left: "5rem",
        },
        rewind: true,
        width: 800,
        perPage: 3,
        perMove: 1,
        gap: "1rem",
        autoplay: true,
      }
    };
  },

  methods: {
    onMounted(slider) { 
      setTimeout(function () { slider.refresh(); }, 3000);
    },

    getRecentPosts() {
      api
        .get("/posts/all", { params : { platform: "atheva_web", limit: 12 }})
        .then(response => {
          this.cards = response.data;
        })
        .catch(err => {
          console.log(err);
        });
    }
  },

  mounted() {
    this.getRecentPosts();
  }
};
</script>

<style scoped>
section {
  display: grid;
  grid-template-columns: 496px 1fr;
  height: 60vh;
  position: relative;
}
h2 {
  font-weight: normal;
  font-size: 32px;
}

.card-item {
  box-shadow: 0px 8px 16px #0000001a;
  border-radius: 8px;
  overflow: hidden;
  background: #fff;
  min-width: 284px;
}

.card-item img {
  width: 284px;
  height: 213px;
  object-fit: cover;
}

.info {
  padding: 16px 24px;
}
.info h3 {
  font-weight: 600;
  font-size: 16px;
  margin: 0;
  margin-bottom: 8px;
}
.info span {
  font-weight: 500;
  font-size: 14px;
  display: block;
  margin-bottom: 16px;
}
.info p {
  font-size: 14px;
  line-height: 1.5;
  margin-bottom: 16px;
  color: #747474;
}
.info a {
  font-weight: 800;
  font-size: 14px;
  /* color: #747474; */
  color: #f9b010;
}
.sub {
  font-size: 18px;
  margin-top: 16px;
}
.footer {
  background: transparent linear-gradient(99deg, #37475a 0%, #232f3e 100%) 0% 0%;
  position: absolute;
  bottom: 0;
  left: -300px;
  width: 150%;
  z-index: -1;
  height: 33vh;
  overflow: hidden;
}
.footer img {
  object-fit: contain;
}

@media screen and (max-width: 768px) {
  section {
    grid-template-columns: 1fr;
    height: auto;
  }
  h2 {
    font-size: 18px;
    margin-bottom: 20px;
    max-width: 300px;
  }
  .sub {
    max-width: 300px;
    font-size: 15px;
    margin-bottom: 40px;
  }
  .cards {
    width: 120%;
  }
  .splide,
  .splide__slide {
    position: initial;
  }
  .info h3 {
    font-size: 16px;
  }
  .info span,
  .info p {
    font-size: 15px;
  }
  .sub {
    font-size: 16px;
  }
  .footer {
    left: -40px;
    width: 150%;
  }
}
</style>