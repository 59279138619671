<template>
  <div class="parceiro" @click="openSite(parceiro.link)">
    <img :src="imgParceiro" :alt="parceiro.alt" />
  </div>
</template>

<script>
export default {
  props: {
    parceiro: {
      type: Object,
    },
  },
  computed: {
    imgParceiro() {
      return require(`@/assets/logos/${this.parceiro.nome}`);
    },
  },
  methods: {
    openSite(link) {
      window.open(link);
    },
  },
};
</script>

<style scoped>
.parceiro {
  padding: 32px;
  width: 165px;
  height: 106px;
  background: #ffffff;
  box-shadow: 0px 8px 16px #0000001a;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
  cursor: pointer;
}
.parceiro:hover {
  transform: scale(1.1);
}
.parceiro img {
  object-fit: contain;
}
</style>